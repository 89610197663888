/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import { typeRamp, colors, themeAccentColorMap } from '../../constants'
import { accentProps, withAccentTheme } from '../../context/AccentThemeContext'

const propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	...accentProps,
}

const defaultProps = {
	title: null,
	children: [],
	accentColor: colors.grey1,
}

const Highlight = ({ children, title, accentTheme }) => {
	return (
		<div
			data-comp="highlight"
			css={{
				'&:before': {
					content: '" "',
					background: themeAccentColorMap[accentTheme],
					width: '1rem',
					height: '0.1875rem',
					display: 'block',
					marginBottom: '1rem',
				},
			}}
		>
			<h4
				css={{
					...typeRamp.med_16,
					marginBottom: '0.5rem',
					display: title ? 'block' : 'none',
				}}
			>
				{title}
			</h4>

			{children}
		</div>
	)
}

Highlight.propTypes = propTypes
Highlight.defaultProps = defaultProps

export default withAccentTheme(Highlight)
