/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_GREEN,
	THEME_WHITE,
	colors,
	media,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
} from '../../components/content/Showcase'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.toyota.next

type Props = {
	accentTheme: string
}

const ToyotaPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			storybookCollage: file(
				relativePath: { eq: "toyota/toyota-storybook-collage.png" }
			) {
				...ImageFluidFull
			}
			prototype: file(relativePath: { eq: "toyota/toyota-prototype.jpg" }) {
				...ImageFluidFull
			}
			ds: file(relativePath: { eq: "toyota/toyota-ds-collage.png" }) {
				...ImageFluidFull
			}
			motionInterations: file(
				relativePath: { eq: "toyota/iris-motion-interactions.jpg" }
			) {
				...ImageFluidFull
			}
			interior2: file(relativePath: { eq: "toyota/iris-interior-02.jpg" }) {
				...ImageFluidFull
			}
			interior1: file(relativePath: { eq: "toyota/iris-interior-01.jpg" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>Toyota</title>
				<meta content="Toyota Case Study" property="og:title" />
				<meta content="Toyota Case Study" name="twitter:title" />
				<meta
					name="description"
					content="Transforming the driving experience."
				/>
				<meta
					property="og:description"
					content="Transforming the driving experience."
				/>
				<meta
					content="https://freeassociation.com/share/toyota.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/toyota.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Toyota</h1>
							<p className="--headline">Transforming the driving experience.</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									We partnered with the Toyota Research Institute (TRI), an R&D
									division of Toyota Motor Corporation, to develop a singular
									design vision that could unite the digital interfaces in
									future Toyota vehicles. We crafted a unique conceptual design
									language and set of guidelines to unify and streamline the
									in-car UX across driving modes and displays, including
									instrument clusters, infotainment consoles, navigation
									systems, integrated applications and HUDs.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>UX &amp; Market Research</li>
									<li>Testing &amp; Measurement</li>
									<li>Information Architecture</li>
									<li>User Experience Design</li>
									<li>UI &amp; Visual Design</li>
									<li>Interaction Prototyping</li>
									<li>Brand Systems &amp; Guidelines</li>
									<li>UX Prototyping</li>
									<li>UI Component Libraries</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<FullBleedImage fluidImageData={data.interior1.childImageSharp.fluid} />
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<FullBleedImage fluidImageData={data.interior2.childImageSharp.fluid} />
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.motionInterations.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Considering the critical safety concerns when designing for
									automotive, we looked deeply into situational awareness and
									driver’s attention. This resulted in an underlying theory
									about attention and vehicle operation that served as the basis
									for our “Iris” design language and guidelines.
								</p>
							</Text>
						</LayoutIndented>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<Showcase
						layout={SHOWCASE_LAYOUT_1UP}
						images={[data.storybookCollage.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“There are approximately 40,000 U.S. auto fatalities per year.
									Our goal is to change that statistic.”
								</p>
								<footer>
									<p className="--small">
										<strong>TRI Press Conference</strong>
										<br />
										CES 2019
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We built a low-fi car prototype to simulate the driving
									experience and rapidly test different interface designs.
									Winning designs were then brought to TRI’s driving simulators
									and test vehicles for further validation and iteration.
								</p>
							</Text>
						</LayoutIndented>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<FullBleedImage fluidImageData={data.prototype.childImageSharp.fluid} />
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Our work brought insight and direction to many of Toyota’s
									diverse challenges.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Multiple Interfaces Working in Harmony">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Our design language called on carefully choreographed
										interactions across various screens within a vehicle’s
										cockpit to keep a driver’s attention on or near the road,
										bringing important information and functionality to their
										field of view rather than forcing them to look away.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Accelerating Innovation & Promotion">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Project Iris hastened innovation within TRI by providing
										common components and UX patterns for engineers, allowing
										them to focus on higher order problems. In addition, it
										helped future innovations to be framed in more appealing
										design from day one, allowing PR to publicize them faster
										and with more confidence.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Seamless UX Across Driving Modalities">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Our work looked across driving modalities (manual driving,
										driving with Guardian<sup>™</sup> and various levels of
										autonomous driving), and at the transition between
										modalities (such as hand-offs and handbacks between manual
										and autonomous driving) to identify common patterns and
										achieve a seamless user experience.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Challenging Workflow &amp; Process">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Through an iterative workflow and design-led approach, we
										demonstrated to Toyota the value of working from the UX
										perspective. Our work became a proof-of-concept for the
										possibilities of a unified design language and design system
										that spans the entire driving experience, from screens to
										lighting, sound to haptics, and beyond.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1280} height={720} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/Iris_Motion-Tests-Final.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“We’re unifying the UX throughout the vehicle. And it’s not
									just the screens, it’s throughout the entire cockpit of the
									car.”
								</p>
								<footer>
									<p className="--small">
										<strong>Thor Lewis</strong>
										<br />
										Director of UX
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage fluidImageData={data.ds.childImageSharp.fluid} />
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(ToyotaPage, THEME_GREEN)
